import $ from 'jquery';

import Aos from 'aos';


// Import only the Bootstrap components we need
//import { Util, Dropdown, Offcanvas, Popover } from 'bootstrap';
//import { Navbar } from 'bootstrap';

//Dependences

//import Swiper, {Navigation, Pagination} from 'swiper';

import 'aos/dist/aos.css'
import '../scss/style.scss';


//import './partials/searching';

const scrollbarWidth = window.innerWidth - document.body.clientWidth
let headerheight = document.querySelector('.main-header').offsetHeight;
document.body.style.setProperty("--scrollbarWidth", `${scrollbarWidth}px`)
document.body.style.setProperty("--headerheight", `${headerheight}px`)


$(document).ready(function() {
  $(window).on('resize', function(){
    headerheight = document.querySelector('.main-header').offsetHeight;
    document.body.style.setProperty("--headerheight", `${headerheight}px`)
  })

  $('.hamburger.mobile-menu').on('click',function(e){
    //console.log('hamburger');
    e.preventDefault();
    $(this).toggleClass('is-active')
    $('.mobile-menu-wrap').toggleClass('show')
    $('.main-header').toggleClass('menu-show')
    //$('.main-header').toggleClass('open')
  })

  

  $('.mobile-menu-wrap .has-children > .nav-link, .mobile-menu-wrap .has-children > .show-submenu').on('click',function(e){
    e.preventDefault();
    $(this).closest('.has-children').find('> .show-submenu').toggleClass('active');
    $(this).closest('.has-children').find('> .sub-menu').animate({
      height: "toggle",
      opacity: "toggle"
    }, 200 );
  })

  var scroll = 0;
  $(window).on('scroll',function(){
    let curscroll = $(window).scrollTop();
    if (curscroll > scroll){
      // downscroll code
      $('.main-header').removeClass('scrollup');
    } else {
      // upscroll code
      $('.main-header').addClass('scrollup');
    }
    if ((curscroll > 100) ) {
      $('.main-header').addClass('scroll');
    }else {
      $('.main-header').removeClass('scroll');
      $('.main-header').removeClass('scrollup');
        
    }
    scroll = curscroll;
  })


  $('.anime-scroll').on('click',function(e){
    e.preventDefault();
    let thishas = $(this).find('.nav-link').attr('href')
    $('html, body').animate({scrollTop: $(thishas).offset().top - 110}, 1000);
  })
  

  Aos.init({
    duration: 900,
  });


  pageheader()
  $(window).on('scroll resize',function(){
    pageheader()
  })


  $('.contact-form').on('submit',function(e){
    e.preventDefault();
    let form = $(this);
    $(this).addClass('form-validate');
   /*  $('.be-error').hide();
    $('.error').hide();
    $('.form-control-wrap').removeClass('form-control-wrap--error')
    $('.kontakt-form-wrap .error-response').hide()
    $('.kontakt-form-wrap .success-response').hide() */
    let isValid = true;
    $('.form-control, .form-check-input',form).each(function() {
      $(this).removeClass('is-invalid')    
      $(this).closest('.form-floating ').removeClass('is-invalid')  
      if ($(this).is(":invalid")) {
        $(this).addClass('is-invalid')    
        $(this).closest('.form-floating ').addClass('is-invalid')    
        /* $(this).closest('.form-control-wrap').addClass('form-control-wrap--error')
        $(this).closest('.form-group').find('.error').show(); */
        isValid = 0;
      }
    });
    if (isValid) {
      $('.loading-loader').show();
      //const formData = new FormData();
      let data = $(this).serialize();
      //formData.push(data);
      //let file =  $('.form-control[name="subor"]',$(this))[0].files[0];
      //formData.append('file', file);
      //console.log(file)
      $.ajax({
        url: ajax_url,
        type: "post",
        dataType: "json",
        data: new FormData(this),
        processData: false,
        contentType: false,
        success: function(data) {
          //console.log(data);
          $('.loading-loader').hide();
          //console.log(data['responce'])
          if (data['responce'] == 'success') {
            $(form).closest('.contact-form-wrap').find('.success-response').show()
          }else {
            $(form).closest('.contact-form-wrap').find('.error-response').show()
          }
        }
      })
    }
  });

  if($('#moove_gdpr_cookie_info_bar').length) {
    let cookies = getCookie('moove_gdpr_popup');
    //console.log(cookies);
    if (cookies == null) {
      //console.log('jeeee');
      var timerID = setInterval(function() {
        if($('#moove_gdpr_cookie_info_bar').is(':visible')) {
          $('#moove_gdpr_cookie_info_bar .moove-gdpr-infobar-settings-btn').trigger('click');
          $('.gdpr_lightbox-wrap').removeAttr('data-gdpr_lightbox-close');
          clearInterval(timerID);
        }
  
        //console.log(timerID)
      }, 1000);
    }
  }

});


function pageheader() {
  if ($(window).width() > 992) {
    if($('.page-header').length) {
      var scroll_top = $(window).scrollTop();
      var sample_section_top = $('.text-page').offset().top;
      var header_height = $('.page-header .header-content').outerHeight();
      $('.text-page').css({ 'margin-top': header_height });
      $('.page-header').css({ 'height': header_height - (scroll_top* 0.6), 'position': 'fixed' });
    }
  }else {
    $('.text-page').css({ 'margin-top': 0 });
    $('.page-header').css({ 'height': 'auto', 'position': 'relative' });
  }
}


function setCookieTrial() {
  var d = new Date();
  d.setTime(d.getTime() + (2*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = "noticepopup=click;" + expires;
}


function setCookie(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}
function eraseCookie(name) {
  document.cookie = name+'=; Max-Age=-99999999;';
}
